import React from "react"
import { graphql, Link } from "gatsby"
import { get } from "lodash"
import { ExternalLink, Layout, TwitterMeta } from "../../components"
import { useSiteMetadata } from "../../utils"
import { Helmet } from "react-helmet"
import "../../assets/stylesheets/application.scss"

function OtherMissions({ missions }) {
  return (
    <ul>
      {
        missions && missions.map((mission) => (
          <li key={mission.id}>
            <Link
              className="link-white-base"
              to={`/missions/${mission.slug}`}
            >
              {mission.mission_fields.title}
            </Link>
          </li>
        ))
      }
    </ul>
  )
}

function Mission({ data, location }) {
  const { siteURL, title: siteTitle } = useSiteMetadata()
  const myURL = `${siteURL}${location.pathname}`
  const slug = data.wpMission.slug
  const missionFields = data.wpMission.mission_fields
  const blogPostUrl = missionFields.blogPostUrl
  const description = missionFields.description
  const subheader = missionFields.teaser
  const externalURL = missionFields.url // URL to external (non-SPACE) site
  const patchURL = get(missionFields, 'missionPatch.sourceUrl')
  const primaryColor = missionFields.primaryColor
  const primaryImageURL = get(missionFields, 'primaryImage.sourceUrl')
  const missionType = missionFields.missionType
  const missionStatus = missionFields.missionStatus
  const launchDate = missionFields.launchDate
  const title = missionFields.title
  const commander = get(missionFields, 'commander.name')
  const teamMembers = missionFields.teamMembers || []
  const tools = missionFields.tools
  const tutorialImage = get(missionFields, 'tutorialImage.sourceUrl')
  const flybys = data.flybys.nodes
  const rockets = data.rockets.nodes
  const satellites = data.satellites.nodes

  return (
    <Layout>
      <Helmet
        title={`${title} - ${siteTitle}`}
        defer={false}
        bodyAttributes={{ class: slug }}
      />
      <TwitterMeta
        title={title}
        image={patchURL}
        url={myURL}
      />

      <header className="page-header mission">
        <div className="header-container">
          {patchURL &&
            <img
              src={patchURL}
              alt=""
              className="badge"
            />
          }
          <div className="mission-intro">
            <h1 className="underline-header-white">{title}</h1>
            <p className="subheader">{subheader}</p>
          </div>
        </div>
      </header>

      <section className="mission-description-container">
        <div className="description-text">
          <h5>How it all Started</h5>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>

        {primaryImageURL &&
          <img
            alt=""
            className="primary-image"
            src={primaryImageURL}
          />
        }
      </section>

      <section
        className="mission-details"
        style={{ backgroundColor: primaryColor }}
      >
        <ul className="mission-details-container">
          <li className="detail-block">
            <i className="Satellite-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Mission Type</h4>
              <p className="subheader">{missionType}</p>
            </div>
          </li>
          <li className="detail-block">
            <i className="telescope-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Website</h4>
              {
                externalURL
                  ? <ExternalLink
                    className="subheader"
                    href={externalURL}
                  >
                    View Live
                    </ExternalLink>
                  : <p className="subheader">N/A</p>
              }
            </div>
          </li>
          <li className="detail-block">
            <i className="landing-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">State</h4>
              <p className="subheader">{missionStatus}</p>
              <p className="subheader">{launchDate}</p>
            </div>
          </li>
          <li className="detail-block">
            <i className="astronaut-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Commander</h4>
              <p className="subheader">{commander}</p>
            </div>
          </li>
          <li className="detail-block">
            <i className="crew-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Crew</h4>
              <p>
                <ul>
                  {teamMembers.map((teammate) => (
                    <li
                      key={teammate.name}
                      className="subheader"
                    >
                      {teammate.name}
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          </li>
          <li className="detail-block">
            <i className="space-gun-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Tools & Methods</h4>
              <p className="subheader">{tools}</p>
            </div>
          </li>
        </ul>
      </section>
      <section className="secondary-media-container">
        <div className="secondary-media">
          {tutorialImage &&
            <img
              alt=""
              className="secondary-image"
              src={tutorialImage}
            />
          }
        </div>
      </section>

      {blogPostUrl &&
        <section class="blog-container">
          {/* <img src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/content_entry566b5b79d7bcec000300000b/59f8df56bb2922001c000004/files/fotofluent-blog-icon-small-square.png?1509484777" class="blog-thumbnail" alt="" /> */}
          <div class="blog-text">
            <h4>Check out the {title} blog</h4>
            {/* <p class="teaser"></p> */}
            <ExternalLink
              className="button-black-outline button-arrow"
              href={blogPostUrl}>
              Read More
            </ExternalLink>
          </div>
        </section>
      }

      <section
        className="more-missions"
        style={{ backgroundColor: primaryColor }}
      >
        <ul className="more-missions-container">
          <h2>Check out more missions</h2>
          <li className="more-block">
            <i className="Flyby-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Flybys</h4>
              <OtherMissions missions={flybys} />
            </div>
          </li>
          <li className="more-block">
            <i className="Rocket-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Rockets</h4>
              <OtherMissions missions={rockets} />
            </div>
          </li>
          <li className="more-block">
            <i className="Satellite-medium"></i>
            <div className="block-text">
              <h4 className="underline-header">Satellites</h4>
              <OtherMissions missions={satellites} />
            </div>
          </li>
        </ul>
      </section>
    </Layout>
  )
}

export default Mission

export const query = graphql`
  query page($id: String!) {
    wpMission(id: { eq: $id }) {
      mission_fields {
        description
        teaser
        url
        missionPatch {
          sourceUrl
        }
        primaryImage {
          sourceUrl
        }
        missionType
        commander {
          name
        }
        tools
        missionStatus
        teamMembers {
          name
        }
        launchDate
        tutorialImage {
          sourceUrl
        }
        blogPostUrl
        primaryColor
        title
      }
      slug
    }
    flybys:
      allWpMission(
        filter: {mission_fields: {missionType: {eq: "Flyby"}}, id: {ne: $id}},
        limit: 3
      ) {
        nodes {
          mission_fields {
            title
          }
          id
          slug
        }
      }
    rockets:
      allWpMission(
        filter: {mission_fields: {missionType: {eq: "Rocket"}}, id: {ne: $id}},
        limit: 3
      ) {
        nodes {
          mission_fields {
            title
          }
          id
          slug
        }
      }
    satellites:
      allWpMission(
        filter: {mission_fields: {missionType: {eq: "Satellite"}}, id: {ne: $id}},
        limit: 3
      ) {
        nodes {
          mission_fields {
            title
          }
          id
          slug
        }
      }
  }
`
